.loginbutton {
  background-color: forestgreen;
  color: white;
  border-radius: 4px;
  width: 85px;
  height: 45px;
}

.login-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}

.login-form label {
  display: block;
  margin: 30px auto;
}

.login-form span {
  display: block;
  margin-bottom: 6px;
}

.login-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
