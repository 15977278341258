.main-grid {
  display: grid;
  grid-template-columns: minmax(1em, 1fr) minmax(0px, 500px) minmax(1em, 1fr);
  grid-column-gap: 2em;
}

.page-title {
  color: #000;
  font-size: 2.5rem;
  justify-self: center;
}
.sub {
  margin-top: 3em;
}

.main-image {
  grid-column: 2 / -2;
  object-fit: cover;
  max-width: 100%;
  display: block;
  box-shadow: 10px 10px 250px #000;
  border-radius: 41px;
}

.main-text {
  grid-column: 2 / -2;
  margin-top: 3em;
  margin-bottom: 3em;
}

.section-title::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  margin-top: 1em;
  background: #fda039;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 600px) {
  .main-image {
    grid-column: 2;
    margin-top: 2.5em;
    height: 80%;
    border-radius: 122px;
  }
}

@media (min-width: 600px) {
  .main-grid {
    grid-template-columns: minmax(1em, 1fr) repeat(3, minmax(20px, 320px)) minmax(
        1em,
        1fr
      );
  }

  .page-title::after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    background: #fda039;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }

  .main-image {
    grid-column: 2;
    margin-top: 3.5em;
    height: 70%;
  }

  .main-text {
    grid-column: 3 / span 2;
    margin-top: 0;
  }

  .section-title::after {
    margin-left: 0;
  }
}

@media (min-width: 700px) {
  .page-title::after {
    content: "";
    display: block;
    width: 160px;
    height: 5px;
    background: #fda039;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }
}
