@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i");

body {
  font-family: "Poppins", sans-serif;
  background: #ddd;
}

img.hover-shadow {
  transition: all 0.3s;
}

.carousel-row {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.carousel-row--img {
  width: 30%;
  margin-bottom: 10px;
}
.carousel-row--img h3,
h5 {
  text-align: center;
  font-weight: 600;
}
.carousel-row--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: 0.4s;
}
.carousel-row--img img:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2),
    0 12px 20px 0 rgba(0, 0, 0, 0.19);
}

.column-box {
  white-space: nowrap;
  background-color: transparent;
  width: 100%;
  background: black;
  overflow: hidden;
}
.column-box .column {
  width: 20%;
  display: inline-block;
  transition: all 1s;
}
.column-box .column img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.shell {
  padding: 80px 0;
}

.shell h2 {
  text-align: center;
  color: #cfaf6c;
}

.shell h4 {
  text-align: center;
  padding-top: 20px;
  text-align: center;
  font-size: x-large;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding-bottom: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 37.5em) {
  .shell h2 {
    text-align: center;
    color: #cfaf6c;
    font-size: large;
  }

  .shell {
    padding: 40px 0;
  }
  .shell h4 {
    padding-top: 10px;
    padding-bottom: 0px;
    font-size: medium;
  }
  .carousel-row--img {
    width: 30%;
    margin-bottom: 10px;
  }
  .carousel-row--img h3 {
    font-size: xx-small;
  }
  .carousel-row--img h5 {
    font-size: xx-small;
  }
  .carousel-row--img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s;
  }
}

@media only screen and (max-width: 56.25em) {
}
@media only screen and (max-width: 37.5em) {
}
