@import "~antd/dist/antd.css";


/* .App-header {
  background-color: #0d0f15;
  min-height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
} */

.App-link {
  color: #ded245;
}
