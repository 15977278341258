.table-column {
  font-weight: 600;
}

.img {
  size: 10px;
}

.logoutbutton {
  float: right;
  background-color: rgb(244, 165, 9);
  color: white;
  font-weight: 600;
  font-size: medium;
  border-radius: 6px;
  padding: 0 30px;
}
