.transfer-success h5 {
    text-align: center;
    font-size: smaller;
 
    
}

.transfer-success h3{
    color: crimson;
    font-weight: 700;
}

.transfer-success p {
    font-weight: 600;
    font-size: larger;
    color:rgba(24, 175, 7, 0.863);
}

