
* {
  box-sizing: border-box;
}


.section-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-center h1 {
  text-align: center;
  color: #fff;
  padding-bottom: 20px;
  font-size: clamp(2rem, 1rem + 10vw, 2.5rem);
}

.booking-form {
  background: rgba(14, 14, 14, 0.7);
  padding: 40px;
  border-radius: 6px;
}

.form-label {
  display: block;
  margin-left: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
  height: 24px;
  font-size: 12px;
  color: #fff;
}

.form-group {
  position: relative;
  margin-bottom: 20px !important;
}

.form-control {
  background-color: #fff;
  height: 50px !important;
  color: #191a1e;
  border: none;
  font-size: 16px !important;
  font-weight: 500 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 40px;
}

.form-checkbox input + span {
  position: absolute;
  left: 2px;
  top: 4px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}

.form-checkbox label + label {
  margin-left: 15px;
}

.form-checkbox label {
  position: relative;
  padding-top: 4px;
  padding-left: 30px;
  font-weight: 400;
  color: #fff;
}

.form-checkbox input {
  position: absolute !important;
  margin-left: -9999px !important;
  visibility: hidden !important;
}

select.form-control + .select-arrow:after {
  content: "\279C";
  display: block;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.submit-btn {
  color: #fff;
  background-color: rgb(211, 157, 21);
  font-weight: 400;
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  border-radius: 40px;
  text-transform: uppercase;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.form-btn {
  margin-top: 27px;
}

.submit-btn:hover,
.submit-btn:focus {
  opacity: 0.9;
}

.form-checkbox input + span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-color: rgb(211, 157, 21);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}





@media only screen and (max-width: 375px) 

 {
  .section-center {
    box-sizing: border-box;
    position: absolute;
    top: 73%;
  }
  .section-center h1 {
    font-size: 1.9rem;
  }
  .form-checkbox input + span {
    left: 2px;
    top: 0px;
  }
  .form-checkbox label + label {
    margin-left: 130px;
    bottom: 27px;
  }

  .form-checkbox label {
    padding-top: 1px;
    padding-left: 25px;
  }

  .container {
   
    max-height:790px;
  }
}

@media only screen and (max-width: 428px) 

 {
  .section-center {
    box-sizing: border-box;
    position: absolute;
    top: 80%;
  }
  .section-center h1 {
    font-size: 1.9rem;
  }
  .form-checkbox input + span {
    left: 2px;
    top: 0px;
  }
  .form-checkbox label + label {
    margin-left: 130px;
    bottom: 27px;
  }

  .form-checkbox label {
    padding-top: 1px;
    padding-left: 25px;
  }

  .container {
   
    max-height:820px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    width: 620px;
    height: fit-content;
  }

  .section-center {
    top: 40%;
  }
  .section-center h1 {
    font-size: 2.1rem;
  }
  .booking-form {
    padding-bottom: 70px;
  }
}


@media only screen and (min-width: 992px) {
  .container {
    width: 970px;
  }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }

  .section-center {
    top: 50%;
  }
  .section-center h1 {
    font-size: 2.5rem;
  }
}
