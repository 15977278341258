@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
}

.main-img {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.home-content-sidebar {
  display: flex;
  max-width: 100%;
  text-align: center;
  background: #fff;
  padding-top: 1%;
}

.one-third {
  float: left;
  padding: 0 70px 40px;
  max-width: 35%;
}

.one-third h3 {
  text-align: center;
  line-height: 1.2;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: rgb(211, 157, 21);
}

.circle {
  float: left;
  width: 88px;
  height: 88px;
  border: 2px solid #34394a;
  border-radius: 45px;
  line-height: 103px;
}

.circle-icon {
  font-size: 40px;
  display: inline-flex;
  color: #001529;
}

.black-cta {
  align-items: center;
  color: #191f26;
  max-height: 100px;
  background-color: #191f26;
  min-width: 100%;
  float: left;
}

.black-cta h1 {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  font-size: clamp(3rem, 1rem + 10vw, 2rem);
}

.card-container {
  display: inline-flex;
  justify-content: center;
  max-width: 104.3%;
}

.card-styles {
  max-width: calc(30.6636%);
  height: auto;
  margin-top: 0px;
  box-sizing: border-box;
}



/*RESPONSIVE */


@media only screen and (max-width: 375px) {

  .main-img {
    object-fit: cover;
    max-width: auto;
    height: 105rem;
  }
  

  .one-third {
    max-height: 65px;
    padding: 0 54px 10px;
  }

  
  .one-third h3 {
    text-align: center;
  }
  .black-cta h1 {
    font-size: 15pt;
  }
  

}

@media only screen and (max-width: 428px) {
  .home-content-sidebar {
    display: grid;
    grid-template-columns: 2fr 1fr;

  }

  .main-img {
    object-fit: cover;
    max-width: auto;
    height: 130rem;
  }


  .one-third {
    align-items: center;
    width: 100%;
    max-height: 130px;
    padding: 12px 30px  10px!important;
  
    
  }
  .circle {
    float: left;
    width: 65px !important;
    height: 65px !important;
    line-height: 10px !important;
  }
  
  .circle-icon {
    font-size: 42px !important;
  padding-top: 10px !important;
  }
  
  

  .one-third h3 {
    font-size: smaller !important;
    text-align:center;
    padding-left: 12px;
    
  }
 
  .black-cta h1 {
    font-size: 17pt;
  }

  
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-img {
    object-fit: cover;
    max-width: auto;
    height: 70rem;
  }

  .one-third {
    width: 100%;
    max-height: 95px;
    padding: 0 17px;
    padding-bottom: 10px;
  }

  .one-third h3 {
    
    font-size: small;
    font-weight: 600;

  }

  .circle {
    float: left;
    width: 55px;
    height: 55px;
    border: 2px solid #34394a;
    border-radius: 45px;
    line-height: 65px;
  }
  
  .circle-icon {
    font-size: 30px;
    display: inline-flex;
    color: #001529;
  }
}
