@import url(https://fonts.googleapis.com/css?family=Arvo);

/* WIDGET */
.widget-wrap {
  font-family: Arvo;
  font-weight: 100;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.widget-wrap h2 {
  padding-bottom: 30px;
  padding-top: 30px;
  color: #cfaf6c;
}

/* (A) GALLERY WRAPPER */
.gallery {
  /* (A1) GRID LAYOUT - 3 IMAGES PER ROW */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  /* (A2) OPTIONAL WIDTH RESTRICT */
  max-width: 1000px;
  margin: 0 auto;
}

/* (B) GALLERY IMAGES */
.gallery img {
  /* (B1) DIMENSION */
  width: 350px;
  height: 250px; /* optional */
  padding: 10px;

  /* (B2) COLORS */
  border: 1px solid #ddd;
  background: #fff;

  /* (B3) IMAGE RESIZE */
  /* cover | contain | fill | scale-down */
  object-fit: cover;
  position: relative;
}

/* (D) OPTIONAL ZOOM ON HOVER */
.gallery img:hover {
  z-index: 9;
  transform: scale(1.3);
  /* linear | ease | ease-in | ease-out | ease-in-out */
  transition: transform ease 0.5s;
}

@media only screen and (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery img {
    width: 158px;
    height: 160px;
  }
}

@media only screen and (min-width: 650px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery img {
    width: 138px;
    height: 170px;
  }
}
@media only screen and (min-width: 768px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
  .gallery img {
    width: 320px;
    height: 250px;
  }
}
