/* 2.form */
.btn.large {
  font-size: 17px;
  width: 200px;
  height: 54px;
  line-height: 54px;
  padding: 0 14px;
}
article {
  display: block;
}
.spin {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.main {
  width: 100%;
  min-height: 600px;
  padding: 0 0 105px;
  overflow: hidden;
  max-width: 100%;
}
.form-overhead {
  font-weight: 600;
  margin-top: 20px;
  font-size: x-large;
}

.form-overhead::after {
  content: "";
  border-top: 1px solid #040934;
  width: 99%;
  display: inline-block;
}
.wrap {
  margin: 0 auto;
  width: 1170px;
  text-align: left;
  position: relative;
  max-width: 94%;
}

.one-fourth {
  float: left;
  width: 25%;
  padding: 0 15px 30px;
  max-width: 100%;
}
.result .one-fourth {
  width: 23%;
  position: relative;
}

.result .one-fourth > div {
  padding: 25px 30px 0 30px;
  height: 100%;
  display: inline-block;
  width: 100%;
  position: relative;
}
  
.meta {
  font-size: 15px;
  color: #858585;
  font-family: 'Montserrat', sans-serif;
    font-weight: 400;

}

.full-width {
  float: left;
  width: 100%;
  padding: 0 15px 30px;
  max-width: 100%;
}
.results {
  float: left;
  width: 100%;
  margin: 10px 0 0;
}

.result {
  float: left;
  width: 100%;
  background: #fff;
  overflow: hidden;
  -webkit-box-shadow: inset 0 0 0 1px #dfdfd0;
  -moz-box-shadow: inset 0 0 0 1px #dfdfd0;
  box-shadow: inset 0 0 0 1px #dfdfd0;
  margin: 0 0 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.result .btn {
  width: 100%;
  float: left;
  margin: 27px 0 0;
}

.result .one-half {
  width: 54%;
  -webkit-box-shadow: 1px 0 0 0 #dfdfd0 inset, -1px 0 0 0 #dfdfd0 inset;
  -moz-box-shadow: 1px 0 0 0 #dfdfd0 inset, -1px 0 0 0 #dfdfd0 inset;
  box-shadow: 1px 0 0 0 #dfdfd0 inset, -1px 0 0 0 #dfdfd0 inset;
}
.result .one-half h3 {
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #dfdfd0;
  width: 100%;
  padding: 22px 30px 20px;
  position: relative;
}

.one-half {
  float: left;
  width: 50%;
  padding: 0 15px 30px;
  max-width: 100%;
}

.result > div {
  padding: 0;
}

.result li {
  width: 33.333%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}
.result ul > li {
  list-style: none;
  position: relative;
  padding: 0 0 10px 15px;
}

.result li p {
  padding: 0;
  line-height: 1.2;
}

.result .price {
  font-size: 25px;
  text-align: center;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.result img {
  padding: 1px 0 0 1px;
  max-height: 99%;
}

.transfer-form-2.btn.large {
  font-size: 17px;
  width: 200px;
  height: 54px;
  line-height: 54px;
  padding: 0 14px;
}
.transfer-form-2 .btn {
  border: none;
  text-transform: uppercase;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #ffdd40;
  display: inline-block;
  text-align: center;
  max-width: 100%;
  background-color: #001529;
}

.result ul {
  margin: 0;
  display: table;
  width: 100%;
  height: 132px;
}

.second-form-icon {
  color: #001529;
  font-size: 36px;
  display: inline-block;
  background: none !important;
  font-family: "themeenergy" !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grey {
  background: #595241;
  background-color: #34394a;
}

/* 3. form */

.fade-in {
  transition-timing-function: ease-in;
}

.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  padding: 10px;
  background: #001529;
  color: white;
  min-width: 40px;
  text-align: center;
  font-size: large;
}

/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.title {
  margin: 10px auto;
  color: #cfb000;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 500;
  text-align: center;
}

input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.67rem 0.8rem;
  margin-top: 0.6rem;
  line-height: 1.2;
  color: #233646;
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  border: 1px solid #d9d9d9;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.ele select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.67rem 0.8rem;
  margin-top: 0.6rem;
  line-height: 1.2;
  color: #233646;
  transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  border: 1px solid #d9d9d9;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
input[type="text"]:focus,
input[type="email"]:focus {
  border: 1px solid rgba(74, 144, 226, 0.7);
  box-shadow: 0px 0px 0 3px rgba(74, 144, 226, 0.3);
}

::placeholder {
  color: #8b9aa6;
  font-size: 15px;
}

label {
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 13px;
  font-weight: 500;
}

input[type="button"],
input[type="submit"] {
  border: 0;
  padding: 0.6rem 0;
  width: 138px;
  border-radius: 5px;
  background: #001529;
  color: #ffdd40;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  transition: all 250ms ease;
}
input[type="button"]:hover,
input[type="submit"]:hover {
  transform: translateY(-3px);
  box-shadow: 1px 10px 16px rgba(68, 104, 194, 0.18);
}
input[type="button"]:active,
input[type="submit"]:active {
  position: relative;
  top: 2px;
  left: 2px;
}

/* ▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬[Start Page Style]▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬▬ */
/*MAIN WRAPPER*/
.quick-reservation {
  background: #fff;
  width: auto;
  margin: 0 auto;
  /* Class margin-auto margin-r-auto margin-l-auto */

  /* mixin & class radius-circle radius-circle-btns */
  box-shadow: 0 43px 100px rgba(68, 104, 194, 0.18);
}

.quick-reservation .container {
  display: grid;
  /* mixin */
  grid-template-columns: repeat(4, 1fr);
  padding: 0 3.2rem;
}
.quick-reservation .container .quick-reservation__form {
  grid-column: span 3;
}
.quick-reservation .container .quick-reservation__form .form__content {
  min-height: 100%;
  padding: 2.5rem 2rem 2.5rem 0;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper:not(:last-child) {
  margin-bottom: 1.8rem;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .ele {
  flex: 1;
  font-size: inherit;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .ele:not(:last-child) {
  margin-right: 1.7rem;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .ele
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .ele
  select {
  width: 100%;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .rate-type,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .first-name,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .last-name,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .email-address,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .phone-number {
  flex: 0;
  /* Important - I used it without planning, but I dont't understand how it works and gave me what i need.*/
  flex-basis: 256px;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .rate-type
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .rate-type
  select,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .first-name
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .first-name
  select,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .last-name
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .last-name
  select,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .email-address
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .email-address
  select,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .phone-number
  input,
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper
  .phone-number
  select {
  width: 100%;
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .more-options {
  margin-top: 5.2rem;
}
.quick-reservation .container .reservation-info .data:not(:last-child) {
  margin-bottom: 2.3rem;
}
.quick-reservation .container .reservation-info .data .data__head {
  color: #8b9aa6;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 500;
}
.quick-reservation .container .reservation-info .data .data__description {
  margin: 0;
  color: #233646;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 800;
}
.quick-reservation .container .form__footer {
  grid-column: 1/-1;
  display: grid;
  /* mixin */
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-end;
  padding: 3rem 0;
}

/*2.form media queries */
@media screen and (max-width: 600px) {
  .result .btn {
    width: 50%;
  }

  .row {
    margin: 0;
  }
}
@media screen and (max-width: 980px) {
  .full-width,
  .three-fourth,
  .one-half,
  .one-third,
  .two-third,
  .one-fourth,
  .one-fifth,
  .one-sixth,
  .hentry.one-half {
    width: 100%;
  }
  .wrap {
    width: 92%;
  }

  .transfer-form-2 .result .btn {
    position: absolute;
    top: 65px;
    left: 45px;
    float: center;
    width: 73.3333%;
  }
  .result .btn {
    position: absolute;
    top: 0;
    left: 0;
    float: right;
    width: 53.3333%;
  }
  .result .meta {
    padding-top: 24px;
  }

  .result .price {
    padding-top: 5px;
  }
  .result .one-fourth > div {
    padding: 25px 30px 20px;
    border-bottom: none;
  }

  .result .one-half h3 {
    position: relative;
    top: 0;
  }
  .result .one-half {
    border-bottom: 1px solid #dfdfd0;
  }
  .result .one-fourth:first-of-type {
    width: 100%;
    text-align: center;
  }
  .result .one-fourth,
  .result .one-half {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    margin: 0;
  }
  .result .one-fourth:first-of-type img {
    margin: 0 auto;
    max-width: 99%;
  }
}

/* 
@media screen and (max-width: 1040px) {
  .result .btn {
    margin-top: 0;
    position: absolute;
    top: 170px;
    left: -52.5%;
    width: 100%;
}
  
  .result .meta {
    padding: 19px 10px 0;
}
  .result .price, .result .meta {
    float: left;
}
  .result .one-fourth > div {
    padding: 21px 30px 34px;
    border-bottom: 1px solid #dfdfd0;
}
  .result .one-half h3 {
    position: absolute;
    top: -180px;
}
  .result .one-half {
    float: right;
    width: 50%;
    margin-top: 180px;
    height: auto !important;
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
  .result {
    position: relative;
}
.result .one-fourth:first-of-type {
  width: 50%;
  left: 0;
  top: 0;
  overflow: hidden;
}
.result .one-fourth {
  width: 50%;
  position: absolute;
  top: 60px;
  left: 50%;
  height: auto !important;
}


}
@media screen and (max-width: 1200px) {
  
  .wrap {
    width: 94%;
}
  .result .one-half {
    width: 50%;
}
  .result .one-fourth:first-of-type {
    width: 25%;
}
.result .one-fourth {
  width: 25%;
}


}  */

/* _____________________________________*/

@media (max-width: 992px) {
  .quick-reservation {
    width: 100%;
    margin: 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .quick-reservation .container {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .quick-reservation .container .quick-reservation__form .form__content {
    padding-right: 0;
  }
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .row-wrapper {
  display: flex;
}
@media only screen and (max-width: 830px) {
  .quick-reservation
    .container
    .quick-reservation__form
    .form__content
    .row-wrapper {
    display: block;
  }
}

@media only screen and (max-width: 830px) {
  .quick-reservation
    .container
    .quick-reservation__form
    .form__content
    .row-wrapper
    .ele {
    width: 100%;
  }
  .quick-reservation
    .container
    .quick-reservation__form
    .form__content
    .row-wrapper
    .ele:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .quick-reservation
    .container
    .quick-reservation__form
    .form__content
    .more-options {
    display: flex;
    flex-direction: column;
  }
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .more-options
  label {
  text-transform: none;
}
@media only screen and (max-width: 500px) {
  .quick-reservation
    .container
    .quick-reservation__form
    .form__content
    .more-options
    label:not(:last-child) {
    margin-bottom: 1.4rem;
  }
}
.quick-reservation
  .container
  .quick-reservation__form
  .form__content
  .more-options
  label:not(:last-child) {
  margin-right: 1.6rem;
}
.quick-reservation .container .reservation-info {
  grid-column: 4/5;
  padding: 2.5rem 0 2.5rem 2rem;
}
@media only screen and (max-width: 992px) {
  .quick-reservation .container .reservation-info {
    padding: 1rem 0 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .quick-reservation .container .form__footer {
    display: block;
  }
}
.quick-reservation .container .form__footer .footer-wrapper {
  grid-column: 1/4;
  justify-self: end;
}
@media only screen and (max-width: 992px) {
  .quick-reservation .container .form__footer .footer-wrapper {
    grid-column: 1/-1;
    justify-self: center;
  }
}
@media only screen and (max-width: 830px) {
  .quick-reservation .container .form__footer .footer-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.quick-reservation
  .container
  .form__footer
  .footer-wrapper
  input[type="button"]:not(:last-child),
.quick-reservation
  .container
  .form__footer
  .footer-wrapper
  input[type="submit"]:not(:last-child) {
  margin-right: 1.3rem;
}
@media only screen and (max-width: 600px) {
  .quick-reservation
    .container
    .form__footer
    .footer-wrapper
    input[type="button"],
  .quick-reservation
    .container
    .form__footer
    .footer-wrapper
    input[type="submit"] {
    width: 100%;
  }
}
@media only screen and (max-width: 830px) {
  .quick-reservation
    .container
    .form__footer
    .footer-wrapper
    input[type="button"]:not(:last-child),
  .quick-reservation
    .container
    .form__footer
    .footer-wrapper
    input[type="submit"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.3rem;
  }
}

/*------------*/
.after {
  z-index: -1;
}
@media (max-width: 992px) {
  .after {
    margin: 200px 30px;
  }
}
