@media screen and (max-width: 960px)  {
  
  /* 1rem = 16px */

  .home-content-sidebar {
    overflow: hidden;
  }
  
  .circle-icon {
    font-size: 14px;
    margin-bottom: 38px;    
    
  }
  .circle {
    width:25px;
    height: 25px;
    border-width:thin;
    align-content:space-around;
 
  }
  .one-third{
  float:inline-start;
  width: 12%;
  display: grid;
 padding-left: 3%;
padding-bottom: 0px;
}
  .one-third h3 {
    font-size: 7px;
   padding-top: 0%;
  padding-bottom: 0%;
  position: relative;
  right: 10px;
  bottom: 22px;
 
  }
}
